<template>
  <div class="Part4">
    <div class="w">
      <div class="titleBlack">
        <div>价值观</div>
      </div>
      <!-- <img src="@/assets/image/aboutus/part4@2x.png" alt="" /> -->
      <div class="box">
        <div class="one">
          <img
            src="@/assets/image/pic/593@2x.png"
            alt
            style="width: 391px; height: 327px"
          />
          <img
            src="@/assets/image/pic/true@2x.png"
            alt
            style="
              width: 164px;
              height: 132px;
              position: absolute;
              top: -9%;
              left: 9%;
            "
          />
          <p
            style="
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #999999;
              opacity: 0.4;
              position: absolute;
              top: 11%;
              left: 66%;
            "
          >
            01
          </p>
          <p
            style="
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333;
              position: absolute;
              top: 35%;
              left: 15%;
            "
          >
            求真
          </p>
          <p
            style="
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 300;
              color: #000;
              position: absolute;
              top: 52%;
              left: 15%;
              width: 76%;
              text-align: left;
            "
          >
            以坚毅之心创造价值不断探求事务的本质和真相
          </p>
        </div>
        <div class="one">
          <img
            src="@/assets/image/pic/593@2x.png"
            alt
            style="width: 391px; height: 327px"
          />
          <img
            src="@/assets/image/pic/speciality@2x.png"
            alt
            style="
              width: 164px;
              height: 132px;
              position: absolute;
              top: -9%;
              left: 9%;
            "
          />
          <p
            style="
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #999999;
              opacity: 0.4;
              position: absolute;
              top: 11%;
              left: 66%;
            "
          >
            02
          </p>
          <p
            style="
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333;
              position: absolute;
              top: 35%;
              left: 15%;
            "
          >
            专业
          </p>
          <p
            style="
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 300;
              color: #000;
              position: absolute;
              top: 52%;
              left: 15%;
              width: 76%;
              text-align: left;
            "
          >
            以钻研之心创造价值不断研发更专业的产品和提供更专业的服务
          </p>
        </div>
        <div class="one">
          <img
            src="@/assets/image/pic/593@2x.png"
            alt
            style="width: 391px; height: 327px"
          />
          <img
            src="@/assets/image/pic/create@2x.png"
            alt
            style="
              width: 164px;
              height: 132px;
              position: absolute;
              top: -9%;
              left: 9%;
            "
          />
          <p
            style="
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #999999;
              opacity: 0.4;
              position: absolute;
              top: 11%;
              left: 66%;
            "
          >
            03
          </p>
          <p
            style="
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333;
              position: absolute;
              top: 35%;
              left: 15%;
            "
          >
            创新
          </p>
          <p
            style="
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 300;
              color: #000;
              position: absolute;
              top: 52%;
              left: 15%;
              width: 76%;
              text-align: left;
            "
          >
            以开放之心做事，积极拥抱变化，引领企业高速发展
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part4 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #fff;
  .titleBlack {
    margin-bottom: 160px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      color: #33333388;
      text-align-last: left;
      text-indent: 2em;
      line-height: 40px;
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
  .box {
    display: flex;
    justify-content: space-around;
  }
  .one {
    position: relative;
  }
}
</style>

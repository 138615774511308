<template>
  <div class="Part5">
    <img src="@/assets/image/pic/604@2x.png" alt="" />
    <div class="box">
      <p style="font-size: 44px;font-weight: 500;color: #FFFFFF;margin-bottom:3%">一村使命</p>
      <p style="font-size: 36px;font-weight: 300;color: #FFFFFF">推动数字化农业生态的可持续发展与繁荣，不断提高效率，构建更加平衡与互惠的生态关系</p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.Part5 {
  min-width: 1200px !important
  ;
  position: relative;
  // padding-top: 20px;
  // box-sizing: border-box;
  // height: 560px;
  // background-color: #6b73f1;
  background-image: linear-gradient(to bottom, #5f7cf5, #746ded);
  .placeholder {
    height: 80px;
  }
  .w {
    height: 480px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      text-align-last: left;
      .title {
        font-size: 36px;
        font-family: SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        margin-bottom: 40px;
      }
      .tip {
        width: 500px;
        font-size: 18px;
        font-family: SourceHanSansCN;
        font-weight: 300;
        color: #ffffff;
        line-height: 40px;
      }
    }
    img {
      // height: 465px;
      // margin-left: 90px;
    }
  }
  img {
    width: 100%;
  }
  .box{
     width: 100%;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%)
  }

}
</style>

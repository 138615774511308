<template>
  <div class="Part3">
    <div class="w">
      <div class="titleBlack">
        <div>企业荣誉</div>
      </div>
      <!-- <img src="@/assets/image/aboutus/part3@2x.png" alt="" /> -->
      <div class="box">
        <div class="one">
          <img src="@/assets/image/pic/1@2x (3).png" alt="">
          <p style="font-size: 20px;font-weight: 400;padding:15px">高新技术企业</p>
          <p style="font-size: 16px;font-weight: 300;color:#666666;">获得2021年高新技术企业证书</p>
        </div>
         <div class="one">
          <img src="@/assets/image/pic/2@2x (1).png" alt="">
          <p  style="font-size: 20px;font-weight: 400;padding:15px">软件企业证书</p>
          <p style="font-size: 16px;font-weight: 300;color:#666666;">获得2021年软件企业证书</p>
        </div>
         <div class="one">
          <img src="@/assets/image/pic/3@2x (2).png" alt="">
          <p  style="font-size: 20px;font-weight: 400;padding:15px">软件产品证书</p>
          <p style="font-size: 16px;font-weight: 300;color:#666666;">获得2021年软件产品证书</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.Part3 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #f9faff;
  .titleBlack {
    margin-bottom: 160px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      color: #33333388;
      text-align-last: left;
      text-indent: 2em;
      line-height: 40px;
      margin-top: 30px;
    }
  }
  img {
    width: 90%;
  }
  .box{
    display: flex;
    justify-content: space-around
  }
}
</style>

<template>
  <div class="Part3">
    <div class="w">
      <div class="titleBlack">
        <div>公司大事件</div>
      </div>
      <img src="@/assets/image/aboutus/part8@2x.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.Part3 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #fbfcff;
  .titleBlack {
    margin-bottom: 160px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      color: #33333388;
      text-align-last: left;
      text-indent: 2em;
      line-height: 40px;
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
}
</style>

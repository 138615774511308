<template>
  <div class="Part2">
    <div class="w">
      <div class="titleBlack">
        <div style="text-align: center">公司简介</div>
        <div class="tipBlack">
          <p>
            合肥一村信息科技有限公司致力于打造中国农业产业+互联网综合服务平台，专业为涉农企业提供解决方案和数字化转型升级提供信息技术支持。
          </p>
          <p>
            公司目前研发的系列产品以移动互联网、物联网、大数据、人工智能等技术为支撑，已建成“1+4数字平台”生态服务系统。服务全面覆盖农业生产（包括农资销售、农机服务、农田管理）、粮食全产业链（包括粮食收购、加工、销售）、仓储与物流、信用担保、农业保险、电子支付等环节。最终实现大田作物种植及农事社会化服务数字化、粮食流通精准化、农产品加工数字化、金融赋能便捷化，促进农业产业高质、高效协同发展。
          </p>
          <p>
            公司是“数字皖农”农业产业互联网（简称“5+8”试点）种业、水产、茶叶一体化建设单位。目前核心客户群有安徽荃银高科种业股份有限公司、山东高速供应链集团有限公司、安徽茶业集团有限公司、合肥市渔业协会、安徽省农科院等
          </p>
        </div>
        <br />
        <!-- <img src="@/assets/video/yicun.mp4" alt="" /> -->
        <video
          src="@/assets/video/yicun.mp4"
          autoplay
          muted
          controls
          controlslist="nodownload noremoteplayback"
          oncontextmenu="return false"
        >
          您的浏览器不支持 video 标签。
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part2 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #fff;
  .titleBlack {
    // margin-bottom: 160px;
    .tipBlack {
      font-size: 20px;
      font-family: Source Han Sans CN;
      color: rgb(96 89 89);
      text-align-last: left;
      text-indent: 2em;
      line-height: 40px;
      margin-top: 30px;
      font-weight: 500;
    }
  }
  video {
    width: 100%;
  }
}
</style>

<template>
  <div class="Solution">
    <!-- 关于我们 -->
    <Part1></Part1>
    <Part2></Part2>
    <!-- 企业荣誉 -->
    <Part8></Part8>
    <Part3></Part3>
    <!-- 企业文化 -->
    <Part4></Part4>
    <Part5></Part5>
    <Part6></Part6>
    <!-- 联系我们 -->
    <Part7></Part7>
  </div>
</template>

<script>
import Part1 from '@/components/aboutus/Part1.vue'
import Part2 from '@/components/aboutus/Part2.vue'
import Part3 from '@/components/aboutus/Part3.vue'
import Part4 from '@/components/aboutus/Part4.vue'
import Part5 from '@/components/aboutus/Part5.vue'
import Part6 from '@/components/aboutus/Part6.vue'
import Part7 from '@/components/aboutus/Part7.vue'
import Part8 from '@/components/aboutus/Part8.vue'

export default {
  name: 'Solution',
  components: {
    Part1,
    Part2,
    Part3,
    Part4,
    Part5,
    Part6,
    Part7,
    Part8
  },

  mounted() {
    var type = this.$route.query.type
    var h1 = document.querySelector('.Part1').offsetTop
    var h3 = document.querySelector('.Part3').offsetTop
    var h4 = document.querySelector('.Part4').offsetTop
    var h7 = document.querySelector('.Part7').offsetTop
    if (type == '1') {
      window.scroll(0, h1)
    } else if (type == '2') {
      window.scroll(0, h3)
    } else if (type == '3') {
      window.scroll(0, h4)
    } else if (type == '4') {
      window.scroll(0, h7)
    }
  },
}
</script>

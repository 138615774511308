<template>
  <div class="Part7">
    <div class="w">
      <div class="titleBlack">
        <div>联系我们</div>
      </div>
      <!-- <img src="@/assets/image/aboutus/part7@2x.png" alt="" /> -->
      <div class="box">
        <div class="left">
          <div class="left-box">
            <div class="box-content">
              <img src="@/assets/image/partTwo/tel@2x.png" alt />
              <span>服务热线：400-013-0551</span>
            </div>
          </div>
          <div class="left-box">
            <div class="box-content">
              <img src="@/assets/image/partTwo/time@2x.png" alt />
              <span>服务时间：周一至周五9:00-18:00</span>
            </div>
          </div>
          <div class="left-box">
            <div class="box-content">
              <img src="@/assets/image/partTwo/http@2x.png" alt />
              <span>
                网址：
                <a href="http://ahyicun.com/">http://ahyicun.com/</a>
              </span>
            </div>
          </div>
          <div class="left-box">
            <div class="box-content">
              <img src="@/assets/image/partTwo/locate@2x.png" alt />
              <span>地址：安徽省合肥市蜀山区潜山路中侨中心C座2203-2208</span>
            </div>
          </div>
        </div>
        <div class="right">
          <img src="@/assets/image/partTwo/map.png" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part7 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #fff;
  .titleBlack {
    margin-bottom: 160px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      color: #33333388;
      text-align-last: left;
      text-indent: 2em;
      line-height: 40px;
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
  .box {
    display: flex;
    justify-content: space-between;
    .left {
      width: 46.1%;
      height: 386px;
      .left-box {
        width: 100%;
        height: 25%;
        border: 1px solid #f2f2f2;
        border-bottom: 0px;
        .box-content {
          padding-top: 31px;
          text-align: left !important;
          padding-left: 21px;
          img {
            width: 9%;
            display: inline-block;
          }
          span {
            padding-left: 24px;
            a {
              color: #000;
              text-decoration: none;
            }
          }
        }
      }
      .left-box:last-child {
        border: 1px solid #f2f2f2;
      }
    }
    .right {
      width: 50.5%;
      height: 386px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

<template>
  <div class="Part1">
    <div class="placeholder"></div>
    <!-- <div class="w">
      <div class="left">
        <span class="title">GIS地图+保险解决方案</span>
        <span class="tip"
          >基于真实农业生产过程的大数据监管，对农产品未来产量预估、历时产量进行统计分析等,
          结合农业保险为农业生产提供强有力的保障</span
        >
      </div>
      <img src="@/assets/image/solution/gis/part1@2x.png" alt="" />
    </div> -->
    <!-- <img src="@/assets/image/aboutus/part1@2x.png" alt="" /> -->
    <div class="box">
      <img src="@/assets/image/pic/banner@2x (2).png" alt="" />
      <div class="one">
        <div class="one-one">
          <img
            src="@/assets/image/pic/834.png"
            alt=""
            style="width: 24px; height: 24px"
          />
        </div>
        <p style="font-size: 48px; font-weight: 400; color: #ffffff">
          关于我们
        </p>
        <p style="font-size: 24px; font-weight: 400; color: #ffffff">
          AbOuT us
        </p>
        <div class="one-two">
          <img
            src="@/assets/image/pic/834.png"
            alt=""
            style="width: 24px; height: 24px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part1 {
  min-width: 1200px !important
  ;
  // padding-top: 20px;
  // box-sizing: border-box;
  // height: 560px;
  // background-color: #6b73f1;
  background-image: linear-gradient(to bottom, #5f7cf5, #746ded);
  .placeholder {
    height: 80px;
  }
  .w {
    height: 480px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      text-align-last: left;
      .title {
        font-size: 36px;
        font-family: SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        margin-bottom: 40px;
      }
      .tip {
        width: 500px;
        font-size: 18px;
        font-family: SourceHanSansCN;
        font-weight: 300;
        color: #ffffff;
        line-height: 40px;
      }
    }
    img {
      // height: 465px;
      // margin-left: 90px;
    }
  }
  img {
    width: 100%;
  }
  .box {
    position: relative;
  }
  .one {
    position: absolute;
    top: 20%;
    left: 45%;
  }
  .one-one {
    position: absolute;
    top: -22%;
    left: -12%;
  }
  .one-two {
    position: absolute;
    top: 90%;
    left: 100%;
  }
}
</style>

<template>
  <div class="Part6">
    <div class="w">
      <div class="titleBlack">
        <div>经营理念</div>
      </div>
      <!-- <img src="@/assets/image/aboutus/part6@2x.png" alt="" /> -->
      <div class="box">
        <div class="one">
          <img src="@/assets/image/pic/1@2x (4).png" alt="" style="width:90%;height:440px">
          <p style="font-size: 24px;font-weight: 400;color: #333333;margin:20px auto">创新服务客户</p>
          <p style="font-size: 20px;font-weight: 500;color: #999999;">01</p>
        </div>
         <div class="one">
           <p style="font-size: 24px;font-weight: 400;color: #333333;margin:20px auto">成就员工价值</p>
          <p style="font-size: 20px;font-weight: 500;color: #999999;">02</p>
          <img src="@/assets/image/pic/2@2x (2).png" alt="" style="width:90%;height:440px">
        </div>
         <div class="one">
          <img src="@/assets/image/pic/3@2x (3).png" alt="" style="width:90%;height:440px">
          <p style="font-size: 24px;font-weight: 400;color: #333333;margin:20px auto">务实探索需求</p>
          <p style="font-size: 20px;font-weight: 500;color: #999999;">03</p>
        </div>
        <div class="one">
           <p style="font-size: 24px;font-weight: 400;color: #333333;margin:20px auto">引领行业发展</p>
          <p style="font-size: 20px;font-weight: 500;color: #999999;">04</p>
          <img src="@/assets/image/pic/4@2x (2).png" alt="" style="width:90%;height:440px">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.Part6 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #fff;
  .titleBlack {
    margin-bottom: 160px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      color: #33333388;
      text-align-last: left;
      text-indent: 2em;
      line-height: 40px;
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
  .box{
    display: flex;
    justify-content: space-between
  }
}
</style>
